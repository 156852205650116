import React, { useEffect } from "react";
import '../css/styles.css';

function redirect(url: string): void {
    window.location.href = url;
}

const Logout: React.FC = () => {

    useEffect(() => {
        const timeout = setTimeout(() => {
            try {
                redirect("/");
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }, 10000); // 10000 milliseconds = 10 seconds

        return () => clearTimeout(timeout);
    }, []); // Removed 'history' from dependency array

    return (
        <main className="index">
            <section>
                <header>
                    <h2>Successvol uitgelogged</h2>
                </header>
                <p>Bedankt</p>
            </section>
        </main>
    );
};

export default Logout;
