import { sha256 } from 'js-sha256';

const superKey = process.env.REACT_APP_SUPER_KEY;

if (!superKey) {
    throw new Error("Super key is not defined in the environment variables");
}

export function hashPassword(password: string): string {
    const passwordWithSuperKey = password + superKey;
    const hash = sha256(passwordWithSuperKey);
    return hash;
}
