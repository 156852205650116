import React from 'react';
import { useUser } from '../middelware/UserContext'; // Correct the path if necessary

interface HeaderItem {
    name: string;
    path: string;
}

interface HeaderProps {
    items: HeaderItem[];
}

const Header: React.FC<HeaderProps> = ({ items }) => {
    const { isAdmin } = useUser();
    const [hasCookie, setHasCookie] = React.useState(false);

    React.useEffect(() => {
        // Check if the cookie is set on the server side
        const checkCookie = async () => {
            try {
                const response = await fetch(process.env.REACT_APP_API_URL + '/check_cookie', {
                    method: 'GET',
                    credentials: 'include',
                });
                if (response.ok) {
                    const data = await response.json();
                    setHasCookie(data.cookie_exists);
                } else {
                    console.error('Failed to check cookie:', response.statusText);
                }
            } catch (error) {
                console.error('Error checking cookie:', error);
            }
        };
        checkCookie();
    }, []);

    const filteredItems = isAdmin ? items : items.filter(item => item.name !== 'Admin');

    async function logout(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
        event.preventDefault(); // Prevent default anchor behavior

        try {
            const response = await fetch(process.env.REACT_APP_API_URL + '/logout', {
                method: 'POST',
                credentials: 'include',
            });
            if (response.status === 200) {
                setHasCookie(false);
                window.location.href = '/'; // Redirect to login or homepage after logout
            }
        } catch (error) {
            console.error('Error logging out:', error);
        }
    }

    return (
        <section>
            <header className="aboveNav">
                <figure className="logoContainer">
                    <img className="logo-left" src="https://images.booqable.com/assets/b85a1552-bdcd-468d-8497-831cb8e79867/clo5k2gzi01z92a85m3299xlllogosoellaartfc-895e54fb2809caa5da37a20188829619ee776364e81d51d3a931efe8c06a5893(2).png" alt="Soellaart logo" />
                    <img className="logo-right" src="https://consumersiteimages.trustpilot.net/business-units/5f64950bd0db0d000166d3f5-198x149-1x.avif" alt="Sport 'N Styles logo" />
                </figure>
            </header>
            <nav>
                <ul className="topnav">
                {hasCookie && (
                        <li className="logout">
                            <a href="/logout" onClick={logout}>Uitloggen</a>
                        </li>
                    )}
                    {filteredItems.map(item => (
                        <li key={item.path} className={item.path === window.location.pathname ? 'active' : ''}>
                            <a href={item.path}>{item.name}</a>
                        </li>
                    ))}
                </ul>
            </nav>
        </section>
    );
};

export default Header;
