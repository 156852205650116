import React from 'react';
import Login from "../elements/login";


const SignIn: React.FC = () => (
  <>
    <Login />
  </>
);

export default SignIn;
