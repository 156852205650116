import React, { useState } from 'react';
import { hashPassword } from '../utils/hashPassword'; // Import the hashPassword function

function redirect(url: string): void {
    window.location.href = url;
}

interface User {
    username: string;
    password: string;
}

const Login: React.FC = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const hashedPassword = hashPassword(password); // Hash the password before sending
        const userdata: User = { username, password: hashedPassword };
        await signinrequest(userdata);
    };

    async function signinrequest(userdata: User) {
        const response = await fetch(process.env.REACT_APP_API_URL + '/login', {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(userdata),
        });

        if (response.status === 401) {
            alert("Wrong username or password");
        } else if (response.status === 200) {
            try {
                redirect("menu");
            } catch (error) {
                console.error('Error redirecting:', error);
            }
        }
    }

    return (
        <main className="index">
            <section>
                <h1>login</h1>
                <p>vul u gebruikersnaam en wachtwoord hier in:</p>
                <form onSubmit={handleFormSubmit}>
                    <label htmlFor="username">gebruikersnaam:</label>
                    <input type="text" id="username" name="username" required value={username} onChange={(e) => setUsername(e.target.value)} />
                    <br />
                    <label htmlFor="password">wachtwoord:</label>
                    <input type="password" id="password" name="password" required value={password} onChange={(e) => setPassword(e.target.value)} />
                    <br />
                    <input type="submit" value="Submit" />
                </form>
            </section>
        </main>
    );
};

export default Login;
