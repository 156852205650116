import React, { useState, useEffect } from "react";
import { hashPassword } from '../utils/hashPassword'; // Import the hashPassword function

interface User {
    id: number;
    username: string;
    role: string;
}

const Admin: React.FC = () => {
    const [users, setUsers] = useState<User[]>([]);
    const [newUser, setNewUser] = useState({ username: "", password: "", role: "werknemer" });
    const [autosaveFiles, setAutosaveFiles] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [doneFiles, setDoneFiles] = useState<string[]>([]);

    useEffect(() => {
        setIsLoading(true);
        fetchUsers();
        fetchAutosaveFiles();
        fetchDoneFiles();
    }, []);

    const fetchUsers = async () => {
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + "/users", {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
            });
            const data = await response.json();
            setUsers(data);
        } catch (error) {
            console.error("Error fetching users:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchAutosaveFiles = async () => {
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + "/autosavefiles", {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
            });
            const data = await response.json();
            setAutosaveFiles(data);
        } catch (error) {
            console.error("Error fetching autosave files:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchDoneFiles = async () => {
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + "/donefiles", {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
            });
            const data = await response.json();
            setDoneFiles(data);
        } catch (error) {
            console.error("Error fetching done files:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleAddUser = async () => {
        try {
            const hashedPassword = hashPassword(newUser.password); // Hash the password before sending
            const response = await fetch(process.env.REACT_APP_API_URL + "/users", {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
                body: JSON.stringify({ ...newUser, password: hashedPassword }), // Send the hashed password
            });
            if (response.ok) {
                fetchUsers();
                setNewUser({ username: "", password: "", role: "werknemer" });
            } else {
                console.error("Error adding user:", response.statusText);
            }
        } catch (error) {
            console.error("Error adding user:", error);
        }
    };

    const handleRemoveUser = async (id: number) => {
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + `/users/${id}`, {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
            });
            if (response.ok) {
                fetchUsers();
            } else {
                console.error("Error removing user:", response.statusText);
            }
        } catch (error) {
            console.error("Error removing user:", error);
        }
    };

    const handleChangePassword = async (id: number, newPassword: string) => {
        try {
            const hashedPassword = hashPassword(newPassword); // Hash the new password
            const response = await fetch(process.env.REACT_APP_API_URL + `/users/${id}/password`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
                body: JSON.stringify({ password: hashedPassword }), // Send the hashed password
            });
            if (response.ok) {
                fetchUsers();
            } else {
                console.error("Error changing password:", response.statusText);
            }
        } catch (error) {
            console.error("Error changing password:", error);
        }
    };

    const handleDeleteAutosaveFile = async (file: string) => {
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + `/deleteautosavefile`, {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
                body: JSON.stringify({ filename: file }),
            });
            if (response.ok) {
                fetchAutosaveFiles();
            } else {
                console.error("Error deleting file:", response.statusText);
            }
        } catch (error) {
            console.error("Error deleting file:", error);
        }
    };

    const handleRestoreAutosaveFile = async (file: string) => {
        try {
            let response = await fetch(process.env.REACT_APP_API_URL + `/restoreautosavefile`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
                body: JSON.stringify({ filename: file }),
            });

            let data = await response.json();

            if (response.ok) {
                if (data.requires_confirmation) {
                    const userConfirmed = window.confirm(data.message);
                    if (userConfirmed) {
                        response = await fetch(process.env.REACT_APP_API_URL + `/restoreautosavefile`, {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json' },
                            credentials: 'include',
                            body: JSON.stringify({ filename: file, confirm_overwrite: true }),
                        });
                        data = await response.json();
                    } else {
                        console.log("User declined to overwrite the existing file.");
                        return;
                    }
                }

                fetchAutosaveFiles();
                alert(data.message);
            } else {
                console.error("Error restoring autosave file:", data.error || response.statusText);
            }
        } catch (error) {
            console.error("Error restoring autosave file:", error);
        }
    };

    const handleDeleteDoneFile = async (file: string) => {
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + `/deletedonefile`, {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
                body: JSON.stringify({ filename: file }),
            });
            if (response.ok) {
                fetchDoneFiles();
            } else {
                console.error("Error deleting done file:", response.statusText);
            }
        } catch (error) {
            console.error("Error deleting done file:", error);
        }
    };

    const handleDownloadFile = async (file: string) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/downloadfile/${encodeURIComponent(file)}`, {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include'
            });
            
            if (response.ok) {
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = file;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            } else {
                console.error("Error downloading done file:", response.statusText);
            }
        } catch (error) {
            console.error("Error downloading done file:", error);
        }
    };

    return (
        <div>
            <h1>Admin pagina</h1>

            <h2>gebruikers toevoegen</h2>
            <form onSubmit={(e) => { e.preventDefault(); handleAddUser(); }}>
                <input
                    type="text"
                    placeholder="Gebruikersnaam"
                    value={newUser.username}
                    onChange={(e) => setNewUser({ ...newUser, username: e.target.value })}
                />
                <input
                    type="password"
                    placeholder="Wachtwoord"
                    value={newUser.password}
                    onChange={(e) => setNewUser({ ...newUser, password: e.target.value })}
                />
                <select
                    value={newUser.role}
                    onChange={(e) => setNewUser({ ...newUser, role: e.target.value })}
                >
                    <option value="werknemer">Werknemer</option>
                    <option value="admin">Admin</option>
                </select>
                <button type="submit">gebruiker toevoegen</button>
            </form>

            <h2>gebruikers</h2>
            {isLoading ? (
                <div className="loading-spinner"></div>
            ) : (
                <ul>
                    {users.map(user => (
                        <li key={user.id}>
                            {user.username} ({user.role})
                            <button onClick={() => handleRemoveUser(user.id)}>verwijderen</button>
                            <button onClick={() => {
                                const newPassword = prompt("vul een nieuw wachtwoord in:", "");
                                if (newPassword) {
                                    handleChangePassword(user.id, newPassword);
                                }
                            }}>verander wachtwoord</button>
                        </li>
                    ))}
                </ul>
            )}

            <h2>Autosave bestanden</h2>
            {isLoading ? (
                <div className="loading-spinner"></div>
            ) : (
                <div className="autosave-files">
                    {autosaveFiles.length === 0 && <p>geen autosave bestanden gevonden</p>}
                    <ul>
                        {autosaveFiles.map(file => (
                            <li key={file}>
                                {file}
                                <button onClick={() => {
                                    if (window.confirm(`weet u zeker dat u deze wil verwijdenen${file}?`)) {
                                        handleDeleteAutosaveFile(file);
                                    }
                                }}>Delete</button>
                                <button onClick={() => handleRestoreAutosaveFile(file)}>herstellen</button>
                                <button onClick={() => handleDownloadFile(file)}>Download</button>
                            </li>
                        ))}
                    </ul>
                </div>
            )}

            <h2>Done bestaden</h2>
            {isLoading ? (
                <div className="loading-spinner"></div>
            ) : (
                <div className="done-files">
                    {doneFiles.length === 0 && <p>geen bestaden gevonden</p>}
                    <ul>
                        {doneFiles.map(file => (
                            <li key={file}>
                                {file}
                                <button onClick={() => {
                                    if (window.confirm(`Are you sure you want to delete ${file}?`)) {
                                        handleDeleteDoneFile(file);
                                    }
                                }}>verwijderen</button>
                                <button onClick={() => handleDownloadFile(file)}>Download</button>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default Admin;
