import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

// Define TypeScript interfaces for data types
interface ProductField {
  label: string;
  value: string;
  regex: string;
  exemple: string;
}

interface ProductDetails {
  productName: string;
  productColor: string;
  productArticleNumber: string;
  imageUrl: string; // imageUrl will be a comma-separated string
  fields: ProductField[];
  barcode: string;
  merk: string;
  [key: string]: any; // for additional unknown fields
}

// Custom hook to fetch product details
const useFetchProductDetails = (rowId: string | undefined) => {
  const [productDetails, setProductDetails] = useState<ProductDetails | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchProductDetails = async () => {
      if (!rowId) {
        setError('Row ID is missing.');
        setLoading(false);
        return;
      }

      // Check if rowId is lower than zero
      if (parseInt(rowId) < 0) {
        setError('Row ID is lower than zero.');
        setLoading(false);
        return;
      }

      try {
        const response = await fetch(process.env.REACT_APP_API_URL + `/productpage/${rowId}`, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
          credentials: 'include',
        });

        if (!response.ok) {
          throw new Error(`Failed to fetch product details: ${response.status}`);
        }

        const data = await response.json();
        setProductDetails(data.productdata);
        setLoading(false);
        setError(null);
      } catch (error: any) {
        setError(error.message || 'Unknown error occurred.');
        setLoading(false);
      }
    };

    fetchProductDetails();
  }, [rowId]);

  return { productDetails, loading, error };
};

// Main component to render product details
const ProductPage: React.FC = () => {
  const { rowId } = useParams<{ rowId: string }>();
  const navigate = useNavigate();
  const { productDetails, loading, error } = useFetchProductDetails(rowId);
  const [imageIndex, setImageIndex] = useState(0);
  const [fieldOptions, setFieldOptions] = useState<{ [key: string]: string[] }>({});
  const [expandedFields, setExpandedFields] = useState<{ [key: string]: boolean }>({});
  const [originalValues, setOriginalValues] = useState<{ [key: string]: string }>({});
  const [formDirty, setFormDirty] = useState(false); // Track if form is dirty
  const [isDone, setIsDone] = useState(false); // Track if the form is marked as done
  const [showSaveSuccess, setShowSaveSuccess] = useState(false); // Track save success popup
  const [saveResponseMessage, setSaveResponseMessage] = useState<string | null>(null); // Track save response message
  const [optionsFetched, setOptionsFetched] = useState<{ [key: string]: boolean }>({}); // Track if options have been fetched for each field
  const [noOptionsMessage, setNoOptionsMessage] = useState<{ [key: string]: boolean }>({}); // Track no options message for each field
  const [veranderMaatserie, setVeranderMaatserie] = useState(false); // Track "verander maatserie" checkbox
  const [veranderKleurserie, setVeranderKleurserie] = useState(false); // Track "verander kleurserie" checkbox
  const [fieldValidity, setFieldValidity] = useState<{ [key: string]: boolean }>({}); // Track field validity
  const [regexOptions, setRegexOptions] = useState<{ [key: string]: string[] }>({}); // Track specific regex options

  // Parse image URLs
  const imageUrls = productDetails?.imageUrl ? productDetails.imageUrl.split(',') : [];

  // Handle image navigation
  const handleImageNavigation = (direction: number) => {
    if (!imageUrls.length) return;

    setImageIndex((current) => {
      const newIndex = current + direction;
      return newIndex < 0 ? imageUrls.length - 1 : newIndex % imageUrls.length;
    });
  };

  // Handle navigation between products with prompt
  const handleNavigation = async (direction: number) => {
    const newRowId = parseInt(rowId || '0') + direction;
    if (newRowId < 0) {
      alert('Row ID cannot be less than zero.');
      return;
    }

    if (formDirty) {
      const userConfirmed = window.confirm('You have unsaved changes. Do you want to save before navigating?');
      if (userConfirmed) {
        const saveSuccess = await handleSave();
        if (!saveSuccess) return; // Abort navigation if save failed
      }
    }

    navigate(`/productpage/${newRowId}`);
  };

  // Fetch field options
  const handleFieldOptions = async (fieldLabel: string) => {
    try {
      const response = await fetch(process.env.REACT_APP_API_URL + `/field-options/${fieldLabel}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
      });

      if (response.ok) {
        const data = await response.json();
        const options = Object.values(data.fieldoptions).map(option => String(option)) as string[];

        setFieldOptions((prev) => ({ ...prev, [fieldLabel]: options }));
        setOptionsFetched((prev) => ({ ...prev, [fieldLabel]: true })); // Mark options as fetched
        setExpandedFields((prev) => ({ ...prev, [fieldLabel]: true })); // Expand field options

        if (options.length === 0) {
          setNoOptionsMessage((prev) => ({ ...prev, [fieldLabel]: true })); // Set no options message if empty
        }
      } else {
        console.error(`Error fetching field options for ${fieldLabel}: ${response.status}`);
      }
    } catch (error) {
      console.error(`Failed to fetch field options for ${fieldLabel}: ${error}`);
    }
  };

  const handeldDone = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_API_URL + `/done`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
      });
      if (response.status === 304) {
        console.log('Product marked as done');
        navigate("/done");
        setIsDone(false);
      } else {
        console.error(`Error marking product as done: ${response.status}`);
      }
    }
    catch {
      console.error(`Failed to mark product as done`);
    }
  };

  // Handle option select
  const handleOptionSelect = (fieldLabel: string, option: string) => {
    if (!(fieldLabel in originalValues)) {
      setOriginalValues((prev) => ({ ...prev, [fieldLabel]: (document.getElementById(fieldLabel) as HTMLTextAreaElement).value }));
    }
    setFormDirty(true);
    (document.getElementById(fieldLabel) as HTMLTextAreaElement).value = option;
  };

  // Undo field change
  const handleUndo = (fieldLabel: string) => {
    if (originalValues[fieldLabel]) {
      (document.getElementById(fieldLabel) as HTMLTextAreaElement).value = originalValues[fieldLabel];
      setFormDirty(true);
    }
  };

  // Toggle field expansion
  const toggleFieldExpansion = (fieldLabel: string) => {
    setExpandedFields((prev) => ({ ...prev, [fieldLabel]: !prev[fieldLabel] }));
  };

  // Handle form submission
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const saveSuccess = await handleSave();
    if (saveSuccess) {
      setFormDirty(false);
      setIsDone(true); // Mark as done after successful save
      setShowSaveSuccess(true); // Show save success popup
      setSaveResponseMessage('Save Successful!'); // Set response message
      setTimeout(() => {
        setShowSaveSuccess(false); // Hide after 3 seconds
        setSaveResponseMessage(null); // Clear response message
      }, 3000);
    }
  };

  // Save form data
  const handleSave = async (): Promise<boolean> => {
    if (!productDetails) return false;

    const formData = new FormData(document.querySelector('form')!);
    const formProps = Object.fromEntries(formData.entries());

    // Add productArticleNumber and checkbox values to the formProps
    formProps['productArticleNumber'] = productDetails.productArticleNumber;
    formProps['veranderMaatserie'] = veranderMaatserie.toString();
    formProps['veranderKleurserie'] = veranderKleurserie.toString();

    try {
      const response = await fetch(process.env.REACT_APP_API_URL + `/productpage/${rowId}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify(formProps),
      });

      if (response.status === 200) {
        console.log('Form data successfully submitted');
        setFormDirty(false);
        return true;
      } else if (response.status === 302) {
        const data = await response.json();
        console.log('Form data successfully submitted');
        setFormDirty(false);
        navigate(data.redirect);
        return true;
      } else {
        console.error(`Error submitting form data: ${response.status}`);
        return false;
      }
    } catch (error) {
      console.error(`Failed to submit form data: ${error}`);
      return false;
    }
  };

  // Handle field value change with regex validation and specific options
  const handleFieldChange = (fieldLabel: string, value: string, regex: string) => {
    setFormDirty(true);
    const isValid = new RegExp(regex).test(value);
    setFieldValidity((prev) => ({ ...prev, [fieldLabel]: isValid }));

    // Check if the regex represents specific options (e.g., (Ja|Nee|\s))
    const match = regex.match(/^\(([^)]+)\)$/);
    if (match) {
      const options = match[1].split('|');
      setRegexOptions((prev) => ({ ...prev, [fieldLabel]: options }));
    } else {
      setRegexOptions((prev) => ({ ...prev, [fieldLabel]: [] }));
    }
  };

  // Render loading, error, or product details
  if (loading) return <div className="loading-spinner"></div>;
  if (error) return <div>Error: {error}</div>;
  if (!productDetails) return <section>Product kenmerken niet gevonden.</section>;

  return (
    <section>
      <main className="index">
        <section className="center">
          <h2>{productDetails.merk}</h2>
          <h2>{productDetails.productName}</h2>
          <div>
            {imageUrls.length > 0 && (
              <>
                {imageUrls.length > 1 && (
                  <button onClick={() => handleImageNavigation(-1)}>{"<"}</button>
                )}
                <img src={imageUrls[imageIndex]} alt={`Display ${imageIndex + 1}`} />
                {imageUrls.length > 1 && (
                  <button onClick={() => handleImageNavigation(1)}>{">"}</button>
                )}
              </>
            )}
          </div>
          <p>Barcode: {productDetails.barcode}</p>
          <p>artikelnummer: {productDetails.productArticleNumber}</p>
          <p>Product kleur: {productDetails.productColor}</p>

          {/* Other dynamic fields */}
          <form className="forms" onSubmit={handleSubmit}>
            <input type="hidden" name="productArticleNumber" value={productDetails.productArticleNumber} />
            {productDetails.fields && productDetails.fields.map((field, index) => (
              <React.Fragment key={index}>
                <label htmlFor={field.label}>{field.label}</label><br />
                {regexOptions[field.label]?.length ? (
                  <select
                    id={field.label}
                    name={field.label}
                    defaultValue={field.value}
                    onChange={(e) => handleFieldChange(field.label, e.target.value, field.regex)}
                  >
                    {regexOptions[field.label].map((option, optIndex) => (
                      <option key={optIndex} value={option}>{option}</option>
                    ))}
                  </select>
                ) : (
                  <textarea
                    id={field.label}
                    name={field.label}
                    defaultValue={field.value}
                    onChange={(e) => handleFieldChange(field.label, e.target.value, field.regex)}
                  />
                )}
                <br />
                {!fieldValidity[field.label] && (
                  <p style={{ color: 'red' }}>Invalid format for {field.label}</p>
                )}
                <p>voorbeeld: {field.exemple || "geen voorbeeld beschikbaar"}</p>
                {optionsFetched[field.label] ? (
                  <>
                    <button type="button" onClick={() => toggleFieldExpansion(field.label)}>
                      {expandedFields[field.label] ? 'Hide Options' : 'Show Options'}
                    </button>
                    {expandedFields[field.label] && (
                      <ul>
                        {fieldOptions[field.label]?.map((option, optIndex) => (
                          <li key={optIndex}>
                            <button type="button" onClick={() => handleOptionSelect(field.label, option)}>{option}</button>
                          </li>
                        ))}
                      </ul>
                    )}
                    {noOptionsMessage[field.label] && <p>No alternatives available.</p>}
                  </>
                ) : (
                  <button type="button" onClick={() => handleFieldOptions(field.label)}>Alternative opties</button>
                )}
                <button type="button" onClick={() => handleUndo(field.label)}>ongedaan maken</button>
                <br />
              </React.Fragment>
            ))}

            {/* Remaining unknown fields */}
            {Object.keys(productDetails)
              .filter(key => !['productName', 'productColor', 'productArticleNumber', 'imageUrl', 'fields', 'barcode', 'merk'].includes(key))
              .map((key) => (
                <React.Fragment key={key}>
                  <label htmlFor={key}>{key}</label><br />
                  <input
                    type="text"
                    id={key}
                    name={key}
                    defaultValue={productDetails[key] || ''}
                    onChange={() => setFormDirty(true)}
                  />
                  {optionsFetched[key] ? (
                    <>
                      <button type="button" onClick={() => toggleFieldExpansion(key)}>
                        {expandedFields[key] ? 'Hide Options' : 'Show Options'}
                      </button>
                      {expandedFields[key] && Array.isArray(fieldOptions[key]) && (
                        <ul>
                          {fieldOptions[key].map((option, optIndex) => (
                            <li key={optIndex}>
                              <button type="button" onClick={() => handleOptionSelect(key, option)}>{option}</button>
                            </li>
                          ))}
                        </ul>
                      )}
                      {noOptionsMessage[key] && <p>geen alternatieve available.</p>}
                    </>
                  ) : (
                    <button type="button" onClick={() => handleFieldOptions(key)}>laat alternatieve opties zien</button>
                  )}
                  <button type="button" onClick={() => handleUndo(key)}>ongedaan maken</button>
                  <br />
                </React.Fragment>
              ))}

            <label>
              <input
                type="checkbox"
                checked={veranderMaatserie}
                onChange={(e) => setVeranderMaatserie(e.target.checked)}
              />
              verander maatserie
            </label>
            <br />
            <label>
              <input
                type="checkbox"
                checked={veranderKleurserie}
                onChange={(e) => setVeranderKleurserie(e.target.checked)}
              />
              verander kleurserie
            </label>
            <br />

            <input type="submit" value="Save" />
          </form>
          <button onClick={() => handleNavigation(-1)}>terug</button>
          <button onClick={() => handleNavigation(1)}>verder</button>
          {/* Mark as Done button */}
          {isDone && <button onClick={() => handeldDone()}>markeer als klaar</button>}
          {/* Save success popup */}
          {showSaveSuccess && <div className="popup">{saveResponseMessage}</div>}
        </section>
      </main>
    </section>
  );
};

export default ProductPage;