import React, { useEffect, useState } from 'react';

// Define the redirect function
function redirect(url: string): void {
  window.location.href = url;
}

// Define TypeScript interfaces for the data structures
interface SubCategory {
  name: string;
  sub_sub_categories: string[];
}

interface NewItem {
  name: string;
  sub_categories: SubCategory[];
}

interface MenuState {
  newItems: NewItem[];
  unfinished: string[];
  loading: boolean;
  error: string | null;
  conflictData: { message: string; pageName: string } | null;
}

// Define the conflict modal component
const ConflictModal: React.FC<{
  message: string;
  onAction: (action: string) => void;
}> = ({ message, onAction }) => {
  return (
    <div className="modal">
      <div className="modal-content">
        <p>{message}</p>
        <div>
          <button onClick={() => onAction('remove')}>Verwijder bestaand bestand en begin opnieuw</button>
          <button onClick={() => onAction('maintain')}>Doorgaan waar u was gebleven</button>
        </div>
      </div>
    </div>
  );
};

// Main Menu component
const Menu: React.FC = () => {
  const [menuState, setMenuState] = useState<MenuState>({
    newItems: [],
    unfinished: [],
    loading: false,
    error: null,
    conflictData: null,
  });
  const [opVoorraad, setOpVoorraad] = useState(false);

  // Fetch task status for menu and handle interval updates
  const fetchTaskStatusMenu = async (taskId: string) => {
    const interval = setInterval(async () => {
      try {
        const response = await fetch(process.env.REACT_APP_API_URL + `/status/menu/${taskId}`, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
          credentials: 'include',
        });

        if (!response.ok) {
          throw new Error(`Failed to fetch status: ${response.status}`);
        }

        const statusData = await response.json();

        if (statusData.status === 'completed') {
          clearInterval(interval);
          setMenuState({
            newItems: statusData.data.new_items,
            unfinished: statusData.data.unfinished,
            loading: false,
            error: null,
            conflictData: null,
          });
        } else if (statusData.status === 'error') {
          clearInterval(interval);
          setMenuState({
            newItems: [],
            unfinished: [],
            loading: true,
            error: 'Probeer het opnieuw',
            conflictData: null,
          });
        } else if (statusData.status === 'unknown') {
          clearInterval(interval);
          setMenuState({
            newItems: [],
            unfinished: [],
            loading: true,
            error: 'Probeer het opnieuw',
            conflictData: null,
          });
          redirect('/menu');
        }
      } catch (error: any) {
        clearInterval(interval);
        setMenuState({
          newItems: [],
          unfinished: [],
          loading: true,
          error: error.message || 'onbekende error opgetreden probeer het opnieuw',
          conflictData: null,
        });
      }
    }, 1000);
  };

  // Fetch task status for product page and handle interval updates
  const fetchTaskStatusProductPage = async (taskId: string) => {
    setMenuState((prevState) => ({ ...prevState, loading: true }));
    const interval = setInterval(async () => {
      try {
        const response = await fetch(process.env.REACT_APP_API_URL + `/status/productpage/${taskId}`, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
          credentials: 'include',
        });

        if (!response.ok) {
          throw new Error(`Failed to fetch status: ${response.status}`);
        }

        const statusData = await response.json();

        if (statusData.status === 'completed') {
          clearInterval(interval);
          redirect(`/productpage/${statusData.data.row_id}`);
        } else if (statusData.status === 'error' || statusData.status === 'unknown') {
          clearInterval(interval);
          setMenuState({
            newItems: [],
            unfinished: [],
            loading: false,
            error: statusData.message || 'Probeer het opnieuw',
            conflictData: null,
          });
        }
      } catch (error: any) {
        clearInterval(interval);
        setMenuState({
          newItems: [],
          unfinished: [],
          loading: false,
          error: error.message || 'onbekende error opgetreden probeer het opnieuw',
          conflictData: null,
        });
      }
    }, 1000);
  };

  // Initiate the task for fetching user menu items
  useEffect(() => {
    const initiateTask = async () => {
      setMenuState((prevState) => ({ ...prevState, loading: true }));
      try {
        const response = await fetch(process.env.REACT_APP_API_URL + '/user-menu-items', {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
          credentials: 'include',
        });

        if (!response.ok) {
          redirect('/');
          throw new Error('Failed to initiate data fetching process');
        }

        const taskData = await response.json();
        fetchTaskStatusMenu(taskData.task_id);
      } catch (error: any) {
        console.error('Error initiating task:', error);
        setMenuState((prevState) => ({
          ...prevState,
          loading: false,
          error: error.message || 'onbekende error opgetreden probeer het opnieuw',
          conflictData: null,
        }));
      }
    };

    initiateTask();
  }, []);

  // Handle page request with conflict resolution
  const handlePageRequest = async (pageName: string) => {
    setMenuState((prevState) => ({ ...prevState, loading: true })); // Set loading state before making the request

    const response = await fetch(process.env.REACT_APP_API_URL + `/page/${pageName}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      body: JSON.stringify({ opVoorraad }),
    });

    if (response.status === 200) {
      const data = await response.json();
      try {
        fetchTaskStatusProductPage(data.task_id); // Fetch task status and handle redirection for product page
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    } else if (response.status === 409) {
      const data = await response.json();
      setMenuState((prevState) => ({
        ...prevState,
        conflictData: { message: data.message, pageName },
        loading: false, // Reset loading state if conflict is detected
      }));
    } else {
      setMenuState((prevState) => ({
        ...prevState,
        loading: false, // Reset loading state on error
        error: 'onbekende error opgetreden probeer het opnieuw',
      }));
    }
  };

  // Handle conflict resolution action
  const handleConflictAction = async (action: string) => {
    if (!menuState.conflictData) return;

    setMenuState((prevState) => ({ ...prevState, loading: true }));

    const { pageName } = menuState.conflictData;
    
    setMenuState((prevState) => ({
      ...prevState,
      conflictData: null,
    }));

    const conflictResponse = await fetch(process.env.REACT_APP_API_URL + `/page/conflict`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      body: JSON.stringify({ action, pageName }),
    });

    if (conflictResponse.status === 202) {
      const conflictData = await conflictResponse.json();
      fetchTaskStatusProductPage(conflictData.task_id); // Fetch task status and handle redirection for product page
    } else {
      setMenuState((prevState) => ({
        ...prevState,
        loading: false,
        error: 'onbekende error opgetreden probeer het opnieuw',
      }));
      console.error('onbekende error opgetreden probeer het opnieuw', conflictResponse.status);
    }
  };

  return (
    <section className='main'>
      {menuState.loading && (
        <div className="loading-spinner"></div>
      )}
      {menuState.error && <div>Error: {menuState.error}</div>}
      {!menuState.loading && !menuState.error && (
        <>
          {menuState.unfinished.length > 0 && (
            <div>
              <h2>bestanden waar u al aan heeft gewerkt</h2>
              <ul>
                {menuState.unfinished.map((item, index) => (
                  <li key={`unfinished-${index}`}>
                    <button
                      className="link-button"
                      onClick={() => handlePageRequest(item)}
                      aria-label={`Unfinished Item: ${item}`}
                    >
                      {item}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          )}
          <div>
            <h2>bestaden waar u aan kan werken</h2>
            <div>
              <input
                type="checkbox"
                id="opVoorraad"
                checked={opVoorraad}
                onChange={(e) => setOpVoorraad(e.target.checked)}
              />
              <label htmlFor="opVoorraad">op voorraad</label>
            </div>
            <ul>
              {menuState.newItems.map((item, index) => (
                <li key={`new-item-${index}`}>
                  <button
                    className="link-button"
                    onClick={() => handlePageRequest(item.name)}
                    aria-label={`New Item: ${item.name}`}
                  >
                    {item.name}
                  </button>
                  {item.sub_categories && (
                    <ul>
                      {item.sub_categories.map((sub, subIndex) => (
                        <li key={`sub-category-${item.name}-${subIndex}`}>
                          <button
                            className="link-button"
                            onClick={() => handlePageRequest(`${item.name}>${sub.name}`)}
                            aria-label={`Sub Category: ${item.name}>${sub.name}`}
                          >
                            {sub.name}
                          </button>
                          {sub.sub_sub_categories && (
                            <ul>
                              {sub.sub_sub_categories.map((subSub, subSubIndex) => (
                                <li key={`sub-sub-category-${item.name}-${sub.name}-${subSubIndex}`}>
                                  <button
                                    className="link-button"
                                    onClick={() => handlePageRequest(`${item.name}>${sub.name}>${subSub}`)}
                                    aria-label={`Sub-Sub Category: ${item.name}>${sub.name}>${subSub}`}
                                  >
                                    {subSub}
                                  </button>
                                </li>
                              ))}
                            </ul>
                          )}
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </>
      )}
      {menuState.conflictData && (
        <ConflictModal
          message={menuState.conflictData.message}
          onAction={handleConflictAction}
        />
      )}
    </section>
  );
};

export default Menu;
