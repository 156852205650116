import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';

// Define the shape of the context data
interface UserContextType {
    isAdmin: boolean;
    checkAdminStatus: () => void;
}

// Create the context with default values
const UserContext = createContext<UserContextType>({
    isAdmin: false,
    checkAdminStatus: () => { },
});

// Custom hook to use the UserContext
export const useUser = () => useContext(UserContext);

// Define the props for the UserProvider
interface UserProviderProps {
    children: ReactNode;
}

// Provider component
export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
    const [isAdmin, setIsAdmin] = useState<boolean>(false);

    const checkAdminStatus = async () => {
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + '/hasadminstatus', {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
            });
            const data = await response.json();
            if (response.ok) {
                setIsAdmin(data.isAdmin);
            } else {
                console.error('Error fetching admin status:', data.error);
            }
        } catch (error) {
            console.error('Error fetching admin status:', error);
        }
    };

    useEffect(() => {
        checkAdminStatus();
    }, []);

    return (
        <UserContext.Provider value={{ isAdmin, checkAdminStatus }}>
            {children}
        </UserContext.Provider>
    );
};
