import React from 'react';

const Done: React.FC = () => (
    <main className="index">
      <section>
        <header>
          <h2>Done</h2>
        </header>
        <p>De categorie is geüpdatet</p>
        <p>super bedankt :)</p>
      </section>
    </main>
);

export default Done;
