import React from "react";
import '../css/styles.css';

const NoPage = () => {
    return (
        <>
            <main className="index">
                <h1 title="404"  />
            </main>
        </>
    )
};

export default NoPage;