import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Menu from './pages/Menu';
import ProductPage from './pages/productPage'; // Update the import statement to use the correct file name

import Header from './components/Header';
import Footer from './components/Footer';
import Done from './pages/Done';
import Logout from './pages/Logout';
import NoPage from './pages/noPage';
import Admin from './pages/Admin';

// Define the navigation items
const navItems = [
    { name: "Admin", path: "/admin" },
    { name: "Home", path: "/menu" },
];

const App: React.FC = () => {
  return (
    <>
      <Header items={navItems} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/menu" element={<Menu />} />
        <Route path="/productpage/:rowId" element={<ProductPage />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/done" element={<Done />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="*" element={<NoPage />} />
      </Routes>
      <Footer />
    </>
  );
};

export default App;
