import React from "react";

const Footer = () => {
  return (
    <footer>
      <section>
        <ul></ul>
      </section>
      <section>
        <p> Created by Sebastiaan / Soellaart &copy; 2024</p>
      </section>
    </footer>
  );
};

export default Footer;
